<template>
  <div class="con-wrap">
    <CarrotTitle title="휴가 관리">
      <div class="btn-ibbox title-btnbox float-right">
        <router-link to="VacationLog" target="_blank"
          ><button class="btn-default">휴가 내역</button></router-link
        >
      </div>
    </CarrotTitle>
    <div class="board">
      <div class="mt-40">
        <div class="mt-10">
          <table class="table-row w-600px float-left">
            <colgroup>
              <col style="width: 160px" />
            </colgroup>
            <tbody>
              <tr>
                <th>이름</th>
                <td>
                  <input type="text" v-model="bbs.stext" class="w-100per" />
                </td>
              </tr>
            </tbody>
          </table>
          <div class="btn-search">
            <button class="btn-default h-30px" @click="bbs.doSearch">
              검색
            </button>
          </div>
          <div class="clear"></div>
        </div>

        <div class="mt-40">
          <carrot-dept
            v-model="bbs.office"
            @change="bbs.doSearch"
            class="w-200px"
          ></carrot-dept>
          <carrot-team
            :idx_office="bbs.office"
            v-model="bbs.team"
            @change="bbs.doSearch"
            class="w-200px ml-5"
          ></carrot-team>
          <select v-model="bbs.state" class="ml-5" @change="bbs.doSearch">
            <option value="DUTY">재직자</option>
            <option value="QUIT">퇴직자</option>
            <option value="LEAVE">휴직자</option>
          </select>
          <button
            type="button"
            @click="bbs.downExcel"
            class="btn-default float-right"
          >
            엑셀 다운로드
          </button>
          <div class="clear"></div>
        </div>
        <div class="mt-20">
          <table class="table-col">
            <!-- <colgroup>
              <col width="50" />
              <col width="140" />
              <col width="100" />
              <col width="110" />
              <col width="140" />
              <col width="100" />
              <col width="60" />
              <col width="50" />
              <col width="60" />
              <col width="50" />
              <col width="60" />
              <col width="50" />
              <col width="60" />
              <col width="50" />
              <col width="60" />
              <col width="50" />
              <col width="60" />
              <col width="50" />
            </colgroup> -->
            <thead>
              <tr>
                <th rowspan="2" width="50">No</th>
                <th rowspan="2" width="220">성명</th>
                <th rowspan="2" width="105">입사일</th>
                <!-- <th rowspan="2">부문</th> -->
                <th rowspan="2" width="105">본부</th>
                <th rowspan="2" width="105">팀</th>
                <th colspan="2" width="120" class="h-40px">루키</th>
                <th colspan="2" width="120" class="h-40px">연차</th>
                <th colspan="2" width="120" class="h-40px">아웃팅</th>
                <th colspan="2" width="120" class="h-40px">전년도이월</th>
                <th colspan="2" width="120" class="h-40px">대체휴가</th>
                <th colspan="2" width="120" class="h-40px">총합</th>
              </tr>
              <tr>
                <th class="h-40px">총지급</th>
                <th class="h-40px">잔여</th>
                <th class="h-40px">총지급</th>
                <th class="h-40px">잔여</th>
                <th class="h-40px">총지급</th>
                <th class="h-40px">잔여</th>
                <th class="h-40px">총지급</th>
                <th class="h-40px">잔여</th>
                <th class="h-40px">총지급</th>
                <th class="h-40px">잔여</th>
                <th class="h-40px">총지급</th>
                <th class="h-40px">잔여</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(irow, i) in bbs.list" :key="i">
                <td>{{ irow.num }}</td>
                <td>
                  <router-link
                    :to="{
                      name: 'myPIMS-VacationMgrInfo-idx',
                      params: { idx: irow.idx },
                    }"
                    target="_blank"
                    ><span class="line-clamp-1" style="text-align: left">{{
                      irow.ename + "(" + irow.kname + ")"
                    }}</span></router-link
                  >
                </td>
                <td>{{ irow.joinday }}</td>
                <!-- <td>
                  <p class="line-clamp-1">{{ irow.sector_name }}</p>
                </td> -->
                <td>
                  <p class="line-clamp-1">{{ irow.office_name }}</p>
                </td>
                <td>
                  <p class="line-clamp-1">{{ irow.team_name }}</p>
                </td>
                <td>{{ irow.tot_rookie }}</td>
                <td>{{ irow.cur_rookie }}</td>
                <td>{{ irow.tot_annual }}</td>
                <td>{{ irow.cur_annual }}</td>
                <td>{{ irow.tot_outing }}</td>
                <td>{{ irow.cur_outing }}</td>
                <td>{{ irow.tot_carryover }}</td>
                <td>{{ irow.cur_carryover }}</td>
                <td>{{ irow.tot_etc }}</td>
                <td>{{ irow.cur_etc }}</td>
                <td>{{ irow.tot }}</td>
                <td>{{ irow.cur_tot }}</td>
              </tr>
              <tr v-if="bbs.total == 0">
                <td colspan="15">휴가 내역이 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from "vue";
import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";
import CarrotDept from "@/components/common/CarrotDept.vue";
import CarrotTeam from "@/components/common/CarrotTeam.vue";

export default {
  layout: "myPIMS",
  components: {
    CarrotDept,
    CarrotTeam,
  },
  setup() {
    const toast = useToast();

    const bbs = reactive({
      page: 1,
      rows: 1000,

      stext: "",
      office: 0,
      team: 0,
      state: "DUTY",

      list: [],
      total: 0,

      downExcel: () => {
        let url =
          "https://api.carrotians.net/excel/vacation_management?office=" +
          bbs.office +
          "&team=" +
          bbs.team +
          "&state=" +
          bbs.state +
          "&stext=" +
          bbs.stext;
        window.open(url, "_blank").focus();
      },

      doSearch: () => {
        let params = {
          page: bbs.page,
          rows: bbs.rows,

          stext: bbs.stext,
          idx_office: bbs.office,
          idx_team: bbs.team,
          state: bbs.state,
        };

        axios
          .get("/rest/mypims/getTeamVacationList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              bbs.list = res.data.list;
              bbs.total = res.data.total;
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }
          });
      },
    });

    onMounted(() => {
      // Mounted
      bbs.doSearch();
    });

    return { bbs };
  },
};
</script>

<style lang="scss" scoped>
.sub-wrap .con-wrap {
  max-width: 1350px;
}

.btn-search {
  float: left;
  margin-top: 13px;
  margin-left: 10px;
}
</style>
